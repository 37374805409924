<template>
  <div class="work-page ca-watch-me">
    
  	<!-- Header -->
  	<section class="header">
      <img src="/static/images/WM2_1.jpg"/>
  		<div class="meta">
  			<p class="client">CRICKET AU</p>
  			<a class="project">WBBL 05</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>A new future for women’s cricket</h1>
  			</div>
  			<div class="col-2">
          <p>A stand-alone season, a season of firsts, a season for women to make their mark on the game. A campaign that signalled the start of a new future for women’s cricket.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <img src="/static/images/WM2_2.jpg" style="width: 70%; float: right"/>

    <div class="spacer-lg" style="clear: both"></div>

    <img src="/static/images/WM2_3.jpg"/>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between; align-items: flex-start; margin-top: 28%">
      <div class="col" style="width: 40%; position: relative; z-index: 2;">
        <img src="/static/images/WM2_4.jpg" style="width: 130%; margin-right: -50%; margin-top: -100%;"/>
      </div>
      <div class="col" style="width: 100%;">
        <img src="/static/images/WM2_5.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="standout-copy" style="text-align: left; width: 100%;">
      We wanted the campaign to be one of leadership, a campaign that set the agenda.
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/WM2_6.jpg"/>

    <div class="spacer-lg"></div>

    <img src="/static/images/WM2_7.jpg"/>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10%;">
        <img style="width: 100%; z-index: 3; position: relative;" src="/static/images/WM2_8.jpg"/>
      </div>
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <img src="/static/images/WM2_9.jpg" style="width: 70%; margin-top: -145%; margin-right: 10%"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/WM2_10.jpg"/>

    <div class="spacer-lg" style="margin-top: -5%;"></div>

    <img src="/static/images/WM2_11.jpg" style="width: 70%; float: right"/>

    <div class="spacer-lg" style="clear: both"></div>

    <img src="/static/images/WM2_12.jpg"/>

    <div class="footer">
      <div class="prev">
        <router-link to="/netball-victoria">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="nike-matildas/">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ca-watch-me',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  mounted() {


  }
}
</script>

<style lang="scss" scoped>
.ca-watch-me-2 {

}
</style>
